import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useState, useEffect, useRef} from 'react';
import { SummaryMainCard } from './SummaryMainCard';
import { SummaryCardSmall } from './SummaryCardSmall';
import { metricsMap } from '../../utils/metrics';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import { API, ShareAPI } from '../../API';


import styles from './SummaryPanel.module.scss';


export const SummaryPanel = ({
  narrativeIds,
  reportId,
  tabsList = ['actors', 'publications', 'views', 'reactions'],
  isPDF = false,
  isShare,
  isStory=false,
  sourceGroups,
  countries,
  startDate,
  endDate
}) => {
  const { t } = useTranslation();
  const abortControllerRef = useRef();
  const [summary, setSummary] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const fetchSummary = () => {
    const api = isShare ? ShareAPI : API;
    const urlParams = new URLSearchParams();

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    if (!isStory) {
      narrativeIds.forEach((narrative_id) =>
        urlParams.append('narrative_ids', narrative_id),
    )}

    let url = `/API/v1/dashboard/narratives/summary?${urlParams.toString()}`;
    
    if (isStory) {
      if(sourceGroups.length > 0) {
        sourceGroups.forEach((sourceGroup) => {
          urlParams.append('source_group_id', sourceGroup.value);
        });
      }
      if(countries.length > 0) {
        countries.forEach((country) => {
          urlParams.append('source_country_ids', country.value);
        });
      }
      if(startDate) {
        urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
      }
      if(endDate) {
        urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
      }
      url = `/API/v1/topic-modeling/${reportId}/events/${narrativeIds[0]}/summary?${urlParams.toString()}`;
    }
    api.fetch('GET', url, null, null, signal)
    .then((data) => {
      setSummary(data);
    });
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  if (!summary) {
    return (
      <div className={`${styles.summaryPanel} ${styles.loader}`}>
        <LoaderSmall />
      </div>
    );
  }

  const value = summary[tabsList[tabIndex]]?.breakdown || {};

  let currentBreakdown = Object.keys(value)
    .filter((metric) => value[metric].total)
    .reduce((obj, key) => {
      obj[key] = value[key];
      return obj;
    }, {});

  if (tabsList[tabIndex] === 'actors') {
    currentBreakdown = value;
  }


  return (
    <div className={styles.summaryPanel}>
      <div className={styles.summaryPanelCards}>
        {tabsList.map((tab, index) => {
          return (
            <SummaryMainCard
              title={tab}
              data={summary[tab].total}
              isActive={
                tabsList.length > 1 && tabIndex === index ? true : false
              }
              onClick={() => setTabIndex(index)}
              key={`summary_main_card_${tab}`}
            />
          );
        })}
      </div>
      <div
        className={
          Object.keys(currentBreakdown).length > 3
            ? styles.summaryPanelBody
            : `${styles.summaryPanelBody} ${styles.center}`
        }
      >
        {Object.keys(currentBreakdown).length > 0 ? (
          Object.entries(currentBreakdown).map(([key, value]) => {
            return (
              <SummaryCardSmall
                title={metricsMap[key]}
                data={value.total}
                key={`summary_small_card_${key}`}
              />
            );
          })
        ) : isPDF ? (
          ''
        ) : (
          <div className={styles.noData}>
            {t('There is no data to display')}
          </div>
        )}
      </div>
    </div>
  );
};
