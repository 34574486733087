import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StoryCard } from '../StoryCard/StoryCard';
import { ReactComponent as ListIcon } from '../../assets/list.svg';
import { ReactComponent as MessageIcon } from '../../pages/NarrativePage/assets/messages.svg';
import { ReactComponent as ArrowIcon } from '../../pages/NarrativePage/assets/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../../pages/NarrativePage/assets/arrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import { Calendar } from '../../pages/SearchPage/Calendar/Calendar';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { Radiobutton } from '../DropdownMenu/Radiobutton';
import { SortingOrder } from '../DropdownMenu/SortingOrder';
import { Filter } from './Filter/Filter';

import styles from './StoriesFeed.module.scss';
import { SkeletonStoryCard } from '../StoryCard/SkeletonStoryCard';

export const StoriesFeed = ({
  report,
  stories,
  opinions = [],
  currentStory,
  onTabChange,
  onFilterChange,
  onStoryChange,
  onOpinionChange,
  onDateChange,
  onSortingChange,
  sourceGroups,
  countries,
  sentiment,
  startDate,
  endDate,
  sorting,
  searchQuery,
  page
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('stories');
  const [activeStory, setActiveStory] = useState();
  const [activeOpinion, setActiveOpinion] = useState(opinions[0]);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [keywordsRef, setKeywordsRef] = useState(null);
  const [read, setRead] = useState({});
  
useEffect(() => {
    setActiveStory(currentStory);
    if (currentStory?.is_read === false && !read[currentStory?.id]) {
      setRead({...read, [currentStory.id]: true});
    }
  }, [currentStory]);

  // useEffect(() => {
  //   setActiveStory(stories[0]);
  //   setActiveOpinion(opinions[0]);
  //   onTabChange(activeTab);
  // }, [activeTab]);

  const initStartDate = null;
  const initEndDate = null;

  const sortingMenu = [
    {
      name: t('Date'),
      value: 'date',
    },
    {
      name: t('Rating'),
      value: 'rating',
    },
  ];

  const sortingOrder = [
    {
      name: t('A-Z'),
      icon: <ArrowIcon />,
      value: true,
    },
    {
      name: t('Z-A'),
      value: false,
      icon: <ArrowDownIcon />,
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <div className={styles.filtersContainer}>
        <div className={styles.calendarWrapper}>
          <Calendar
            onChange={(start, end) => {
              onDateChange(start, end);
              // if (keywordsRef?.current) {
              //   setSearchQuery(keywordsRef?.current);
              // }
            }}
            startDate={startDate}
            endDate={endDate}
            initStartDate={startDate}
            initEndDate={endDate}
            overrideStartDate={startDate}
            overrideEndDate={endDate}
          />
        </div>
        <div className={styles.filters}>
          <DropdownMenu
            isOpen={dropdownMenu}
            header={t('Sort by')}
            onClick={() => setDropdownMenu(!dropdownMenu)}
            // buttonName={t('Sort')}
            icon={<SortIcon />}
            menuClassName="only-icon"
          >
            <Radiobutton
              itemsList={sortingMenu}
              key={sorting.fieldName}
              current={sorting.fieldName}
              onChange={(value) =>
                onSortingChange({ ...sorting, fieldName: value })
              }
            />

            <SortingOrder
              itemsList={sortingOrder}
              onClick={(value) =>
                onSortingChange({ ...sorting, isAscending: value })
              }
              current={sorting.isAscending}
            />
          </DropdownMenu>

          <Filter
            onlyIcon={true}
            className="onlyIcon"
            sourceGroupsFilter={sourceGroups}
            countriesFilter={countries}
            sentimentFilter={sentiment}
            onChange={(sourceGroups, countries, sentiment) => {
              onFilterChange(sourceGroups, countries, sentiment);
            }}
          />
        </div>
      </div>
      <div className={styles.feedMainContainer}>
        <div className={styles.controls}>
          <div
            className={
              activeTab === 'stories'
                ? `${styles.tab} ${styles.active}`
                : styles.tab
            }
            onClick={() => setActiveTab('stories')}
          >
            <ListIcon />
            {t('Stories')}
          </div>
          {/* <div
            className={activeTab === 'opinions' ? `${styles.tab} ${styles.active}` : styles.tab}
            onClick={() => setActiveTab('opinions')}
          >
            <MessageIcon />
            {t('Opinions')}
          </div> */}
        </div>

        {activeTab === 'stories' ? (
          stories ? (
            stories.length > 0 ? (
              <div className={styles.feedContainer}>
                {stories?.map((story) => (
                  <StoryCard
                    key={story?.id}
                    story={story}
                    isActive={activeStory?.id === story?.id}
                    isRead={read[story?.id] || story?.is_read}
                    onChange={(story) => onStoryChange(story)}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.feedContainer}>
                <div className={styles.noData}>
                  {searchQuery?.trim().length > 0 || sourceGroups?.length > 0 || countries?.length > 0 || sentiment?.length > 0 || startDate !== new Date(report.start_date) || endDate !== new Date(report.end_date)
                    ? t(
                        'No results were found for this query, refine your search',
                      )
                    : t('There is no data to display')}
                </div>
              </div>
              
            )
          ) : (
            <div className={styles.feedContainer}>
              {[...Array(3).keys()].map((story) => (
                <SkeletonStoryCard key={story} />
              ))}
            </div>
          )
        ) : (
          ''
        )}
        {activeTab === 'opinions' ? (
          opinions ? (
            opinions.length > 0 ? (
              <div className={styles.feedContainer}>
                {opinions?.map((opinion) => (
                  <StoryCard
                    key={opinion?.id}
                    story={opinion}
                    isActive={activeOpinion?.id === opinion?.id}
                    onChange={(opinion) => setActiveOpinion(opinion)}
                    isStory={false}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.feedContainer}>
                <div className={styles.noData}>
                  {searchQuery?.trim().length > 0
                    ? t(
                        'No results were found for this query, refine your search',
                      )
                    : t('There is no data to display')}
                </div>
              </div>
            )
          ) : (
            <div className={styles.feedContainer}>
            {[...Array(3).keys()].map((story) => (
              <SkeletonStoryCard key={story} />
            ))}
          </div>
          )
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
