import { format } from 'date-fns';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip/Tooltip';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { API, ShareAPI } from '../../API';
import { ReactComponent as ChevronDown } from '../../pages/NarrativePage/assets/chevron-down.svg';
import { ReactComponent as ShareThreatIcon } from '../../assets/shareThreat.svg';
import { SummaryPanel } from '../SummaryPanel/SummaryPanel';
import { SentimentBar } from '../SentimentBar/SentimentBar';
import { ActorsTypes } from '../ActorsTypesWidget/ActorsTypes';
import { TopActorsWidget } from '../TopActorsWidget/TopActorsWidget';
import { sentimentData } from './sentiment';
import { trendData } from './evolution';
import { OpinionCard } from '../OpinionCard/OpinionCard';
import { GeoChart } from '../GeoChart/GeoChart';
import { opinionsData } from './opinions';
import { Loader } from '../Loader/Loader';
import { LoaderSmall } from '../LoaderSmall/LoaderSmall';
import {ReactComponent as NotFoundIcon} from '../../assets/notFound.svg'; 

import styles from './StoryContent.module.scss';
import { EvolutionChart } from '../EvolutionChart/EvolutionChart';


export const StoryContent = ({
  report,
  story,
  isShare,
  isData=true,
  sourceGroups,
  countries,
  sentiment,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const descriptionRef = useRef(null);
  const [currentUser] = useCurrentUser();
  const [isExportMenuOpen, setIsExportMenuOpen] = useState(false);
  const [storyData, setStoryData] = useState(null);
  const [sentimentData, setSentimentData] = useState(null);
  const [opinions, setOpinions] = useState(null);
  
  const scrollToWidget = () => {
    descriptionRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', passive: true})
  }


  const [statsAggregationOptions] = useState(() => [
    { value: 'DAY', label: t('Day') },
    { value: 'WEEK', label: t('Week') },
    { value: 'MONTH', label: t('Month') },
  ]);
  const [statsBreakdownOptions] = useState(() => [
    { value: null, label: t('All') },
    { value: 'PLATFORM', label: t('Platform') },
    { value: 'COUNTRY', label: t('Country') },
    { value: 'ACTOR_GROUP', label: t('Actor group') },
  ]);

  const [viewOptions] = useState(() => [
    {value: 'STANDARD', name: t('Standard')},
    {value: 'VALUES', name: t('Values')},
    {value: 'POINTS', name: t('Points')}
  ])

  const api = isShare ? ShareAPI : API;
  const [totalEvolutionChart, setTotalEvolutionChart] = useState('publications');
  const [storyShares, setStoryShares] = useState(trendData[story?.id]);

  const [statsAggregation, setStatsAggregation] = useState(
    () => statsAggregationOptions[0],
  );

  const [statsBreakdown, setStatsBreakdown] = useState(
    () => statsBreakdownOptions[0],
  );

  const [chartViewOptions, setChartViewOptions] = useState(() => viewOptions[0]);

  const fetchStory = () => {
    if (!story) return;
    setStoryData(null);
    api.fetch('GET', `/API/v1/topic-modeling/${report?.id}/events/${story?.id}`).then((data) => {
      setStoryData(data);
      if(descriptionRef?.current !== null) {
        scrollToWidget();
      }
      
    });
  };

  useEffect(() => {
    setTotalEvolutionChart('publications');
    setStatsAggregation(statsAggregationOptions[0]);
    setStatsBreakdown(statsBreakdownOptions[0]);
    fetchStory();
  }, [story]);

  const fetchSentiment = () => {
    if (!story) return;
    const urlParams = new URLSearchParams();
    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_id', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }
    api.fetch('GET', `/API/v1/topic-modeling/${report?.id}/events/${story?.id}/sentiment?${urlParams.toString()}`).then((data) => {
      setSentimentData(data);
    });
  };

  useEffect(() => {
    fetchSentiment();
  }, [story]);

  const fetchOpinions = () => {
    if (!story) return;
    const urlParams = new URLSearchParams();
    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_id', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }
    api.fetch('GET', `/API/v1/topic-modeling/${report?.id}/events/${story?.id}/opinions?${urlParams.toString()}`).then((data) => {
      setOpinions(data);
    });
  };

  useEffect(() => {
    fetchOpinions();
  }, [story]);


  const fetchStoryShares = (story, statsAggregation, statsBreakdown) => {
    if (!story) return;
    const urlParams = new URLSearchParams();

    urlParams.set('aggregation', statsAggregation);
    urlParams.set('cumulative', false)
    urlParams.set('top', 6)

    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_id', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    let url = `/API/v1/topic-modeling/${report.id}/events/${story.id}/messages/stats?${urlParams.toString()}`;

    if (statsBreakdown !== null) {
      urlParams.set('breakdown', statsBreakdown);
      url = `/API/v1/topic-modeling/${report.id}/events/${story.id}/messages/stats?${urlParams.toString()}`;
    }

    api.fetch('GET', url)
    .then((data) => {
      setStoryShares(data);
    });
  }

  useEffect(() => {
    fetchStoryShares(story, statsAggregation.value, statsBreakdown.value)
  }, [statsAggregation, statsBreakdown, story])



  const handleCreateCase = () => {
    api.fetch('POST', `/API/v1/topic-modeling/${report.id}/events/${story.id}/to_case`)
    .then((data) => {
      window.open(`/narratives/${data}`, '_blank');
    }
    );
    
  };

  if(!isData){
    return (
      <div className={styles.noDataContainer}>
        <NotFoundIcon />
      </div>
    )
  }

  if (!story || !storyData) {
    return <Loader />;
  } 

  if (descriptionRef?.current !== null) {
    scrollToWidget();
  }


  return (
    <div className={styles.storyContentContainer}>
      <div ref={descriptionRef} className={styles.storyDescriptionContainer}>
        <div className={styles.storyDescriptionHeader}>
          <h3 className={styles.storyTitle}>{storyData?.title}</h3>
          <div className={styles.storyDescriptionControls}>
            <Tooltip content={t('Save as case')} position="bottom">
              <button
                type="button"
                className={styles.saveButton}
                onClick={() => {
                  handleCreateCase();
                }}
              >
                <ShareThreatIcon />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className={styles.storyContent}>{storyData?.description}</div>
      </div>

      <div className="report-section">
        <h3>{t('Summary')}</h3>
        <SummaryPanel
          reportId={report.id}
          narrativeIds={[storyData?.id]}
          isShare={isShare}
          isStory={true}
          sourceGroups={sourceGroups}
          countries={countries}
          startDate={startDate}
          endDate={endDate}
        />
      </div>

      <SentimentBar
        data={sentimentData}
        entity={story}
        entityType={'story'}
        tabList={['messages', 'posts', 'comments', 'reactions']}
      />

      <div className="report-section">
        <h3>{t('Top opinions')}</h3>
        {opinions ? (
          opinions?.objects
            ?.slice(0, 5)
            .map((opinion, index) => (
              <OpinionCard key={index} opinion={opinion} />
            ))
        ) : (
          <div className={styles.widgetLoader}>
            <LoaderSmall />
          </div>
        )}
      </div>

      <div className="report-section">
        <h3>{t('Geography')}</h3>
        <GeoChart
          report={report}
          story={story}
          sourceGroups={sourceGroups}
          countries={countries}
          startDate={startDate}
          endDate={endDate}
        />
      </div>

      <TopActorsWidget
        report={report}
        story={story}
        isShare={isShare}
        sourceGroups={sourceGroups}
        countries={countries}
        startDate={startDate}
        endDate={endDate}
      />

      <ActorsTypes
        report={report}
        story={story}
        sourceGroups={sourceGroups}
        countries={countries}
        startDate={startDate}
        endDate={endDate}
      />

      <EvolutionChart
        data={storyShares}
        narrative={story}
        showMessageManipulationIndex={false}
        chartTab={totalEvolutionChart}
        agregation={statsAggregation}
        breakdown={statsBreakdown}
        view={chartViewOptions}
        onChange={(tab, agregation, breakdown, view) => {
          setTotalEvolutionChart(tab);
          setStatsAggregation(agregation);
          setStatsBreakdown(breakdown);
          setChartViewOptions(view);
        }}
      />
    </div>
  );
};
