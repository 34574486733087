export const mapThreatsTypesConst = {
  'FAKE_NEWS': 'Fake news',
  'DATA_LEAK': 'Data leak',
  'COORDINATED_ATTACK': 'Coordinated online attack',
  'COORDINATED_ACTIVITY': 'Coordinated offline activity',
  'HOSTILE_NARRATIVE': 'Malign narrative',
  'DEBUNK': 'Debunk',
  'POSITIVE_NARRATIVE': 'Positive narrative',
  'GOVERNMENT_COMMUNICATION': 'Government communication',

  'FAKE_DISINFORMATION': 'Fake/Disinformation',
  'MANIPULATION_MISINFORMATION': 'Manipulation/Misinformation',
  'DISPROOF_DEBUNK': 'Disproof/Debunk',
  'STRATEGIC_NARRATIVE_MESSAGE': 'Strategic narrative/message (reporting)',
  'UNCLASSIFIED_THREAT': 'Unclassified threat',
  'OTHER': 'Other_',

  'CONSUMER_BACKLASH': 'Consumer backlash',
  'NEGATIVE_MEDIA_COVERAGE': 'Negative media coverage',
  'INSIDER_LEAK': 'Insider leak',
  'NATURAL_DISASTER': 'Natural disaster',
  'POLITICAL_CIVIL_UNREST': 'Political/Civil unrest',
  'CRIMINAL_ACTIVITY': 'Criminal activity',
  'LEGAL_REGULATORY': 'Legal/Regulatory',
  'PRODUCT_MALFUNCTION': 'Product malfunction',
  'CYBER_ATTACK': 'Cyber attack',
  'GEOPOLITICAL_ESCALATION': 'Geopolitical escalation',
  'LARGE_SCALE_ECOLOGICAL_INDUSTRIAL_DISASTER': 'Large-scale ecological/industrial disaster',
  'DETERIORATION_OF_INTERNATIONAL_RELATIONS': 'Deterioration of international relations',
  'GLOBAL_MALFUNCTION': 'Global malfunction',
  'DISINFORMATION_CAMPAIGN': 'Disinformation campaign',
}

export const mapThreatsStatus = {
  'PENDING': 'Pending',
  'ACCEPTED': 'Accepted',
  'DECLINED': 'Declined'
}

export const mapThreatsSubjects = {
  'POLITICS': 'Politics',
  'FINANCE': 'Economy and finance',
  'PUBLIC_COMPANIES': 'Public companies',
  'ELECTIONS': 'Elections',
  'DEFENSE': 'Defence',
  'THREAT': 'Threat',
  'CYBER': 'Cyber',
  'EUROPEAN_INTEGRATION': "European Integration",
  'UKRAINE__NATO': "Ukraine <> NATO",
  'HEALTHCARE': "Healthcare",
  'SOCIAL_CULTURAL': "Social/cultural",
  'DECOLONIZATION': "Decolonization",
  'ENERGY_INDEPENDENCE': "Energy Independence",
  'ANTI_CORRUPTION': "Anti-corruption",
  'ECOLOGY': "Ecology",
  'POST_WAR_RECOVERY': "Reconsruction of Ukraine",
  'BUSINESS_AND_INVESTMENTS': "Business & investments",
  'PUBLIC_SERVICES': "Public services",
  'VOLUNTEERS': "Volunteers",
  'NATIONAL_MINORITIES': "National minorities",
  'COLLABORANTS': "Collaborants",
  'LOCAL_GOVERNMENT': "Local Governance",
  'WEAPON_SUPPLY': "Weapon supply",
  'UKRAINE__PARTNERS': "Ukraine <> Partners",
  'REFORMS': "Reforms",
  'REGION_SPECIFIC': "Region-specific",
  'RUSSIAN_NARRATIVES': "Russian narratives",
  'MOBILIZATION': "Mobilization",
  'CONSTITUTIONAL_STATE': 'Constitutional State',
  'DEFENSE_AND_SECURITY': 'Defense and Security',
  'ARMED_FORCES_REFORM': 'Reform of the Armed Forces',
  'ARMED_FORCES_CORRUPTION': 'Corruption in the Armed Forces',
  'FRONTLINE_REPORT': 'Frontline report',
  'PEACE_TALKS': 'Peace talks',

  'TECH': 'Tech',
  'SPORT': 'Sport',
  'AUTOMOTIVE': 'Automotive',
  'AEROSPACE': 'Aerospace',
  'ENTERTAINMENT': 'Entertainment',
  'PHARMACEUTICS': 'Pharmaceutics',
  'MEDIA': 'Media',
  'RETAIL': 'Retail',
  'FOOD': 'Food',
  'ECOMMERCE': 'E-commerce',
}

export const GEO_SCOPE_OPTIONS = [
  {
    value: 'Ukraine',
    label: 'Ukraine',
  },
  {
    value: 'Russia',
    label: 'Russia',
  },
  {
    value: 'Kherson region',
    label: 'Kherson region',
  },
  {
    value: 'Zaporizhya region',
    label: 'Zaporizhya region',
  },
  {
    value: 'Donetsk region',
    label: 'Donetsk region',
  },
  {
    value: 'Luhansk region',
    label: 'Luhansk region',
  },
  {
    value: 'Mykolayiv region',
    label: 'Mykolayiv region',
  },
  {
    value: 'Kharkiv region',
    label: 'Kharkiv region',
  },
  {
    value: 'Crimea',
    label: 'Crimea',
  },
  {
    value: 'ToT',
    label: 'ToT',
  },
  {
    value: 'Moldova',
    label: 'Moldova',
  },
];


export const INCIDENT_TYPE_OPTIONS = [
  {
    value: 'FAKE_NEWS',
    label: 'Fake news',
  },
  {
    value: 'DATA_LEAK',
    label: 'Data leak',
  },
  {
    value: 'HOSTILE_NARRATIVE',
    label: 'Malign narrative',
  },
  {
    value: 'COORDINATED_ATTACK',
    label: 'Coordinated online attack',
  },
  {
    value: 'COORDINATED_ACTIVITY',
    label: 'Coordinated offline activity',
  },
  {
    value: 'DEBUNK',
    label: 'Debunk',
  },
  {
    value: 'POSITIVE_NARRATIVE',
    label: 'Positive narrative',
  },
  {
    value: 'GOVERNMENT_COMMUNICATION',
    label: 'Government communication',
  },
  {
    value: 'FAKE_DISINFORMATION',
    label: 'Fake/Disinformation',
    is_ucbi: true,
  },
  {
    value: 'MANIPULATION_MISINFORMATION',
    label: 'Manipulation/Misinformation',
    is_ucbi: true,
  },
  {
    value: 'DISPROOF_DEBUNK',
    label: 'Disproof/Debunk',
    is_ucbi: true,
  },
  {
    value: 'STRATEGIC_NARRATIVE_MESSAGE',
    label: 'Strategic narrative/message (reporting)',
    is_ucbi: true,
  },
  {
    value: 'UNCLASSIFIED_THREAT',
    label: 'Unclassified threat',
    is_ucbi: true,
  },
  {
    value: 'OTHER',
    label: 'Other_',
    is_ucbi: true,
  },
  {
    value: 'CONSUMER_BACKLASH',
    label: 'Consumer backlash',
  },
  {
    value: 'NEGATIVE_MEDIA_COVERAGE',
    label: 'Negative media coverage',
  },
  {
    value: 'INSIDER_LEAK',
    label: 'Insider leak',
  },
  {
    value: 'NATURAL_DISASTER',
    label: 'Natural disaster',
  },
  {
    value: 'POLITICAL_CIVIL_UNREST',
    label: 'Political/Civil unrest',
  },
  {
    value: 'CRIMINAL_ACTIVITY',
    label: 'Criminal activity',
  },
  {
    value: 'LEGAL_REGULATORY',
    label: 'Legal/Regulatory',
  },
  {
    value: 'PRODUCT_MALFUNCTION',
    label: 'Product malfunction',
  },
  {
    value: 'CYBER_ATTACK',
    label: 'Cyber attack',
  },
  {
    value: 'GEOPOLITICAL_ESCALATION',
    label: 'Geopolitical escalation',
  },
  {
    value: 'LARGE_SCALE_ECOLOGICAL_INDUSTRIAL_DISASTER',
    label: 'Large-scale ecological/industrial disaster',
  },
  {
    value: 'DETERIORATION_OF_INTERNATIONAL_RELATIONS',
    label: 'Deterioration of international relations',
  },
  {
    value: 'GLOBAL_MALFUNCTION',
    label: 'Global malfunction',
  },
  {
    value: 'DISINFORMATION_CAMPAIGN',
    label: 'Disinformation campaign',
  },
]

export const INCIDENT_TYPE_OPTIONS_UCBI = [
  {
    value: 'FAKE_DISINFORMATION',
    label: 'Fake/Disinformation1',
    is_ucbi: true,
  },
  {
    value: 'MANIPULATION_MISINFORMATION',
    label: 'Manipulation/Misinformation1',
    is_ucbi: true,
  },
  {
    value: 'DISPROOF_DEBUNK',
    label: 'Disproof/Debunk1',
    is_ucbi: true,
  },
  {
    value: 'STRATEGIC_NARRATIVE_MESSAGE',
    label: 'Strategic narrative/message (reporting)1',
    is_ucbi: true,
  },
  {
    value: 'UNCLASSIFIED_THREAT',
    label: 'Unclassified threat1',
    is_ucbi: true,
  },
  {
    value: 'OTHER',
    label: 'Other_1',
    is_ucbi: true,
  },
]

export const TOPICS_OPTIONS = [
  {
    value: 'POLITICS',
    label: 'Politics',
  },
  {
    value: 'FINANCE',
    label: 'Economy and finance',
  },
  {
    value: 'PUBLIC_COMPANIES',
    label: 'Public companies',
  },
  {
    value: 'ELECTIONS',
    label: 'Elections',
  },
  {
    value: 'THREAT',
    label: 'Threat',
  },
  {
    value: 'DEFENSE',
    label: 'Defence',
  },
  {
    value: 'CYBER',
    label: 'Cyber',
  },
  {
    value: 'EUROPEAN_INTEGRATION',
    label: 'European Integration',
  },
  {
    value: 'UKRAINE__NATO',
    label: 'Ukraine <> NATO',
  },
  {
    value: 'HEALTHCARE',
    label: 'Healthcare',
  },
  {
    value: 'SOCIAL_CULTURAL',
    label: 'Social/cultural',
  },
  {
    value: 'DECOLONIZATION',
    label: 'Decolonization',
  },
  {
    value: 'ENERGY_INDEPENDENCE',
    label: 'Energy Independence',
  },
  {
    value: 'ANTI_CORRUPTION',
    label: 'Anti-corruption',
  },
  {
    value: 'ECOLOGY',
    label: 'Ecology',
  },
  {
    value: 'POST_WAR_RECOVERY',
    label: 'Reconsruction of Ukraine',
  },
  {
    value: 'BUSINESS_AND_INVESTMENTS',
    label: 'Business & investments',
  },
  {
    value: 'PUBLIC_SERVICES',
    label: 'Public services',
  },
  {
    value: 'VOLUNTEERS',
    label: 'Volunteers',
  },
  {
    value: 'NATIONAL_MINORITIES',
    label: 'National minorities',
  },
  {
    value: 'COLLABORANTS',
    label: 'Collaborants',
  },
  {
    value: 'LOCAL_GOVERNMENT',
    label: 'Local Governance',
  },
  {
    value: 'WEAPON_SUPPLY',
    label: 'Weapon supply',
  },
  {
    value: 'UKRAINE__PARTNERS',
    label: 'Ukraine <> Partners',
  },
  {
    value: 'REFORMS',
    label: 'Reforms',
  },
  {
    value: 'REGION_SPECIFIC',
    label: 'Region-specific',
  },
  {
    value: 'RUSSIAN_NARRATIVES',
    label: 'Russian narratives',
  },
  {
    value: 'MOBILIZATION',
    label: 'Mobilization',
  },
  {
    value: 'CONSTITUTIONAL_STATE',
    label: 'Constitutional State',
  },
  {
    value: 'DEFENSE_AND_SECURITY',
    label: 'Defense and Security',
  },
  {
    value: 'TECH',
    label: 'Tech'
  },
  {
    value: 'SPORT',
    label: 'Sport'
  },
  {
    value: 'AUTOMOTIVE',
    label: 'Automotive'
  },
  {
    value: 'AEROSPACE',
    label: 'Aerospace'
  },
  {
    value: 'ENTERTAINMENT',
    label: 'Entertainment'
  },
  {
    value: 'PHARMACEUTICS',
    label: 'Pharmaceutics'
  },
  {
    value: 'MEDIA',
    label: 'Media'
  },
  {
    value: 'RETAIL',
    label: 'Retail'
  },
  {
    value: 'FOOD',
    label: 'Food'
  },
  {
    value: 'ECOMMERCE',
    label: 'E-commerce'
  },
]

export const TOPICS_OPTIONS_UCBI = [
  {
    value: 'POLITICS',
    label: 'Politics',
  },
  {
    value: 'FINANCE',
    label: 'Economy and finance',
  },
  {
    value: 'PUBLIC_COMPANIES',
    label: 'Public companies',
  },
  {
    value: 'ELECTIONS',
    label: 'Elections',
  },
  {
    value: 'THREAT',
    label: 'Threat',
  },
  {
    value: 'DEFENSE',
    label: 'Defence',
  },
  {
    value: 'CYBER',
    label: 'Cyber',
  },
  {
    value: 'EUROPEAN_INTEGRATION',
    label: 'European Integration',
  },
  {
    value: 'UKRAINE__NATO',
    label: 'Ukraine <> NATO',
  },
  {
    value: 'HEALTHCARE',
    label: 'Healthcare',
  },
  {
    value: 'SOCIAL_CULTURAL',
    label: 'Social/cultural',
  },
  {
    value: 'DECOLONIZATION',
    label: 'Decolonization',
  },
  {
    value: 'ENERGY_INDEPENDENCE',
    label: 'Energy Independence',
  },
  {
    value: 'ANTI_CORRUPTION',
    label: 'Anti-corruption',
  },
  {
    value: 'ECOLOGY',
    label: 'Ecology',
  },
  {
    value: 'POST_WAR_RECOVERY',
    label: 'Reconsruction of Ukraine',
  },
  {
    value: 'BUSINESS_AND_INVESTMENTS',
    label: 'Business & investments',
  },
  {
    value: 'PUBLIC_SERVICES',
    label: 'Public services',
  },
  {
    value: 'VOLUNTEERS',
    label: 'Volunteers',
  },
  {
    value: 'NATIONAL_MINORITIES',
    label: 'National minorities',
  },
  {
    value: 'COLLABORANTS',
    label: 'Collaborants',
  },
  {
    value: 'LOCAL_GOVERNMENT',
    label: 'Local Governance',
  },
  {
    value: 'WEAPON_SUPPLY',
    label: 'Weapon supply',
  },
  {
    value: 'UKRAINE__PARTNERS',
    label: 'Ukraine <> Partners',
  },
  {
    value: 'REFORMS',
    label: 'Reforms',
  },
  {
    value: 'REGION_SPECIFIC',
    label: 'Region-specific',
  },
  {
    value: 'RUSSIAN_NARRATIVES',
    label: 'Russian narratives',
  },
  {
    value: 'MOBILIZATION',
    label: 'Mobilization',
  },
  {
    value: 'CONSTITUTIONAL_STATE',
    label: 'Constitutional State',
  },
  {
    value: 'DEFENSE_AND_SECURITY',
    label: 'Defense and Security',
  },
  {
    value: 'ARMED_FORCES_REFORM',
    label: 'Reform of the Armed Forces',
  },
  {
    value: 'ARMED_FORCES_CORRUPTION',
    label: 'Corruption in the Armed Forces',
  },
  {
    value: 'FRONTLINE_REPORT',
    label: 'Frontline report',
  },
  {
    value: 'PEACE_TALKS',
    label: 'Peace talks',
  },
]
