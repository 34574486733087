import { format } from 'date-fns';
import { useEffect, useState, useContext, useRef } from "react";
import { Chart } from "react-google-charts";
import { API, ShareAPI } from "../../API";
import { useTranslation } from "react-i18next";
import { LoaderSmall } from "../LoaderSmall/LoaderSmall";

import styles from './ActorsTypes.module.scss';

const audienceData = {
  "1": {
    "objects": [
      { "bucket": "very_small", "count": 55 },
      { "bucket": "small", "count": 398 },
      { "bucket": "medium", "count": 156 },
      { "bucket": "big", "count": 25 },
      { "bucket": "very_big", "count": 11 }
    ]
  },
  "2": {
    "objects": [
      { "bucket": "very_small", "count": 2 },
      { "bucket": "small", "count": 6 },
      { "bucket": "medium", "count": 20 },
      { "bucket": "big", "count": 26 },
      { "bucket": "very_big", "count": 5 }
    ]
  },
  "3": {
    "objects": [
      { "bucket": "very_small", "count": 1 },
      { "bucket": "small", "count": 6 },
      { "bucket": "medium", "count": 39 },
      { "bucket": "big", "count": 18 },
      { "bucket": "very_big", "count": 0 }
    ]
  },
  "4": {
    "objects": [
      { "bucket": "very_small", "count": 7 },
      { "bucket": "small", "count": 7 },
      { "bucket": "medium", "count": 11 },
      { "bucket": "big", "count": 5 },
      { "bucket": "very_big", "count": 4 }
    ]
  },
  "5": {
    "objects": [
      { "bucket": "very_small", "count": 2 },
      { "bucket": "small", "count": 51 },
      { "bucket": "medium", "count": 39 },
      { "bucket": "big", "count": 14 },
      { "bucket": "very_big", "count": 0 }
    ]
  },
  "6": {
    "objects": [
      { "bucket": "very_small", "count": 20 },
      { "bucket": "small", "count": 55 },
      { "bucket": "medium", "count": 9 },
      { "bucket": "big", "count": 2 },
      { "bucket": "very_big", "count": 1 }
    ]
  },
  "7": {
    "objects": [
      { "bucket": "very_small", "count": 10 },
      { "bucket": "small", "count": 39 },
      { "bucket": "medium", "count": 9 },
      { "bucket": "big", "count": 1 },
      { "bucket": "very_big", "count": 1 }
    ]
  },
  "8": {
    "objects": [
      { "bucket": "very_small", "count": 2 },
      { "bucket": "small", "count": 32 },
      { "bucket": "medium", "count": 17 },
      { "bucket": "big", "count": 5 },
      { "bucket": "very_big", "count": 0 }
    ]
  },
  "9": {
    "objects": [
      { "bucket": "very_small", "count": 1 },
      { "bucket": "small", "count": 25 },
      { "bucket": "medium", "count": 9 },
      { "bucket": "big", "count": 3 },
      { "bucket": "very_big", "count": 5 }
    ]
  },
  "10": {
    "objects": [
      { "bucket": "very_small", "count": 5 },
      { "bucket": "small", "count": 21 },
      { "bucket": "medium", "count": 8 },
      { "bucket": "big", "count": 5 },
      { "bucket": "very_big", "count": 0 }
    ]
  },
  "11": {
    "objects": [
      { "bucket": "very_small", "count": 13 },
      { "bucket": "small", "count": 26 },
      { "bucket": "medium", "count": 9 },
      { "bucket": "big", "count": 1 },
      { "bucket": "very_big", "count": 1 }
    ]
  },
  "12": {
    "objects": [
      { "bucket": "very_small", "count": 0 },
      { "bucket": "small", "count": 7 },
      { "bucket": "medium", "count": 0 },
      { "bucket": "big", "count": 0 },
      { "bucket": "very_big", "count": 0 }
    ]
  }
}




export const AudienceChart = ({
  report,
  story,
  isShare,
  isClickable=true,
  sourceGroups,
  countries,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const [audience, setAudience] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState(null);
  const api = isShare ? ShareAPI : API;
  const abortControllerRef = useRef();

  const bucketNameMap = {
    'very_small': t('Very small (< 1k)'),
    'small': t('Small (1k - 10k)'),
    'medium': t('Medium (10k - 100k)'),
    'big': t('Big (100k - 500k)'),
    'very_big': t('Very big (> 500k)')
  }

  const audienceSizeMap = {
    'very_small': [0, 1000],
    'small': [1000, 10000],
    'medium': [10000, 100000],
    'big': [100000, 500000],
    'very_big': [500000, 1000000000]
  }

  const getAudience = () => {
    if (!story) return;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const urlParams = new URLSearchParams();
    urlParams.append('breakdown', 'audience');
    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_id', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const signal = abortController.signal;

    api.fetch('GET', `/API/v1/topic-modeling/${report?.id}/events/${story?.id}/sources/breakdown?${urlParams.toString()}`, null, null, signal).then(
      data => setAudience(data)
    )
  }

  useEffect(() => {
    getAudience()
  }, [story])

  const colors = ['#BEFDEE', '#7FFADD', '#45F1C9', '#1CD6AA', '#00BA8D'];

  const getChartData = (audience) => {
    let data = [
      ['Audience', 'Number', { role: "style" }, { role: 'annotation' }]
    ];
    
    audience.objects?.forEach((audience, index) => 
       data.push([bucketNameMap[audience.audience_size], audience.sources_count, colors[index], audience.sources_count])
    )
    
    return data;
  }

  const options = {
    chartArea: { left: '5%', top: '15%', width:'100%', height:'75%' },
    legend: 'none',
    bar: { groupWidth: "55%" },
    annotations: {alwaysOutside: true, stem: {color: 'transparent'}, textStyle: {bold: true, opacity: 1, color: '#151419'}, highContrast: false}
  }

  return (
    <>
      {audience ? (audience.objects?.length > 0
      ? <div className={styles.chartWrapper}>
        <Chart
          chartType="ColumnChart"
          data={getChartData(audience)}
          width={"100%"}
          height={"400px"}
          className='barChart'
          options={options}
          chartEvents={
            isClickable 
             ? [
                {
                  eventName: "select",
                  callback({ chartWrapper }) {
                    let selected = chartWrapper.getChart().getSelection();
                    let index = selected[0]?.row;
                    let bucket = audience.objects[index].bucket;
                    // let option = {value: 'audience', label: 'Audience',}
                    setFilter(audienceSizeMap[bucket])
                    setIsModalOpen(true);
                  }
                }
              ]
             : []
            
          }
          style={{margin: '0px', padding: '0px'}}
          chartVersion='51'
        />
      </div> 
      : <div className={styles.noData}>{t('There is no data to display')}</div>) 
      : (<div className={styles.widdgetLoader}><LoaderSmall /></div>)}
    </>
  )
};