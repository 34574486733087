import * as d3 from 'd3';
import { format } from 'date-fns';
import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { API, ShareAPI } from "../../API";
import { useTranslation } from "react-i18next";
import styles from './GeoChart.module.scss';
import Modal from "../Modal";
import { LoaderSmall } from "../LoaderSmall/LoaderSmall";


export const GeoChart = ({
  report,
  story,
  sourceGroups,
  countries,
  startDate,
  endDate
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState({isVisible: false, content: ''});
  const [countriesData, setCountriesData] = useState(null);

  const fetchCountriesData = () => {
    const urlParams = new URLSearchParams();
    if(sourceGroups.length > 0) {
      sourceGroups.forEach((sourceGroup) => {
        urlParams.append('source_group_id', sourceGroup.value);
      });
    }
    if(countries.length > 0) {
      countries.forEach((country) => {
        urlParams.append('source_country_ids', country.value);
      });
    }
    if(startDate) {
      urlParams.append('date_from',format(startDate, 'yyyy-LL-dd'));
    }
    if(endDate) {
      urlParams.append('date_to',format(endDate, 'yyyy-LL-dd'));
    }

    API.fetch('GET', `/API/v1/topic-modeling/${report.id}/events/${story.id}/countries/breakdown?${urlParams.toString()}`).then(data => {
      setCountriesData(data);
    }
    )
  }

  useEffect(() => {
    fetchCountriesData();
  }, [])

  const getChartData = (data) => {
    if(data.objects.length === 0) return [['Country', 'Sentiment', 'Messages'], ['No data', 0, 0]];
    let chartData = [[t('Country'), t('Sentiment'), t('Messages')]];
    data?.objects?.forEach(country => {
      chartData.push([country.name, Math.floor(country.average_sentiment_score * 100), country.messages_count]);
    });
    return chartData;
  }

  const data = [
    ['Country',   'Sentiment', 'Messages'],
    ['France',  -50, 657],
    ['Germany', 27, 818],
    ['Poland', -23, 385],
    ['Ukraine',  50, 1000],
    ['Russia',  71, 500],
    ['Belarus',  -38, 300],
    ['USA',  50, 1000],
    ['Canada',  71, 500],
    ['Mexico',  -38, 3000],
  ];

  const options = {
    // sizeAxis: { minValue: 0,},
    region: "world", // Western Europe
    displayMode: "markers",
    colorAxis: { colors: ["#EF4444", "#F5D90A", '#41B871'], values: [-100, 0, 100]},
    keepAspectRatio: true,
    magnifyingGlass: {enable: true, zoomFactor: 7.5},
    datalessRegionColor: '#e9effd',
    legend: 'none',
    markerOpacity: 0.7,
  };

  const countriesDeteils = {
    'France': {
      country: 'France',
      opinionBalance: {positive: 50, negative: 50},
      messages: 657
    },
    'Germany': {
      country: 'Germany',
      opinionBalance: {positive: 27, negative: 73},
      messages: 818
    },
    'Poland': {
      country: 'Poland',
      opinionBalance: {positive: 23, negative: 77},
      messages: 385
    },
    'Ukraine': {
      country: 'Ukraine',
      opinionBalance: {positive: 50, negative: 50},
      messages: 1000
    },
    'Russia': {
      country: 'Russia',
      opinionBalance: {positive: 71, negative: 29},
      messages: 500
    },
    'Belarus': {
      country: 'Belarus',
      opinionBalance: {positive: 38, negative: 62},
      messages: 300
    },
    'USA': {
      country: 'USA',
      opinionBalance: {positive: 50, negative: 50},
      messages: 1000
    },
    'Canada': {
      country: 'Canada',
      opinionBalance: {positive: 71, negative: 29},
      messages: 500
    },
    'Mexico': {
      country: 'Mexico',
      opinionBalance: {positive: 38, negative: 62},
      messages: 3000
    },
  }


  if (!countriesData) {
    return <div className={styles.widgetLoader}> <LoaderSmall /> </div>
  }

  return (
    <div className={styles.chartWrapper}>
      <Chart
          // chartEvents={[
            // {
            //   eventName: "select",
            //   callback: ({ chartWrapper }) => {
            //     const chart = chartWrapper.getChart();
            //     const selection = chart.getSelection();
            //     const dataTable = chartWrapper.getDataTable();
            //     if (selection.length > 0) {
            //       var row = selection[0].row;
            //       var country = dataTable.getValue(row, 0); // Get country name from the selected row
            //       setModal({isVisible: true, content: countriesDeteils[country]});
            //       console.log('Selected country: ' + country);
            //     }
            //   },
            // },
          // ]}
          options={options}
          chartType="GeoChart"
          width="100%"
          height="500px"
          data={getChartData(countriesData)}
          mapsApiKey="AIzaSyD_zlgApSQgpEZHNntjwmQC1lcwU9c9fms"
          chartVersion='51'
          
        />

        {countriesData.objects.length === 0 ? <div className={styles.noDataContainer}>{t('There is no data to display')}</div> : ''}

        {modal.isVisible ? <Modal 
          isVisible={modal.isVisible}
          title={t('Country details')}
          content={
            <div>
              <div>
                <h4>{t('Country')}: {modal.content.country}</h4>
                <div>{t('Opinion balance')}: </div>
                <div>{t('Positive')}: {modal.content.opinionBalance.positive}%</div>
                <div>{t('Negative')}: {modal.content.opinionBalance.negative}%</div>
              </div>
              <div>
                <span>{t('Messages')}: {modal.content.messages}</span>
              </div>
            </div>
          }
          footer={<button onClick={() => setModal({isVisible: false, content: ""})}>{t('Close')}</button>}
          onClose={() => {
            setModal({isVisible: false, content: ""});
          }}
        /> : ''}
    </div>
  );
};